@font-face {
    font-family: Ubuntu;
    font-style: normal;
    src: url('../fonts/ubuntu-v15-latin-regular.woff2') format("woff2");
    src: url('../fonts/ubuntu-v15-latin-regular.woff') format("woff");
}

@font-face {
    font-family: Ubuntu;
    font-style: italic;
    src: url('../fonts/ubuntu-v15-latin-italic.woff2') format("woff2");
    src: url('../fonts/ubuntu-v15-latin-italic.woff') format("woff");
}

html {
    @apply text-gray-900;
}